<div class="mainform" style="text-align: center;width:100%;">

  <br>

  <div class="main-message" *ngIf="config && config.user && config.user.user">
   Bonjour,
  </div>

  <app-tuto label="Cet écran vous présente les informations liées à votre compte d'utilisateur de Data Culture"></app-tuto>

  <mat-accordion style="display: inline-block;width:90%;max-width: 800px;" *ngIf="config && config.user">
    <mat-expansion-panel  style="text-align: left;" id="pnlGeneral" [expanded]="true">
      <mat-expansion-panel-header>Général</mat-expansion-panel-header>
      <table style="border-spacing: 20px;">
        <tr>
          <th>Votre email</th>
          <td >{{config.user.user.email}}</td>
        </tr>
        <tr>
          <th>Profil d'utilisateur</th>
          <td>
            {{config.profils[config.user.profil_name]?.title}}<br>
            <span style="opacity: 0.6">{{config.profils[config.user.profil_name]?.presentation}}</span>
          </td>
        </tr>
        <tr>
          <th>Mode expert</th>
          <td>
            <mat-slide-toggle [ngModel]="config.user.level==1"
                              (click)="switch_expert_mode()"></mat-slide-toggle>
          </td>
        </tr>
        <tr *ngIf="environment.optin_options.length>0">
          <th>
            {{environment.optin_label}}
          </th>
          <td>
            <div *ngFor="let option of config.user.optin">
              <app-input
                [label]="option.label" value_type="boolean"
                [value]="option.value"
                *ngIf="option.profil.indexOf(config.user.profil_name)>-1"
                (valueChange)="update_compte($event,option)"></app-input>
            </div>

          </td>
        </tr>
      </table>



    </mat-expansion-panel>

    <mat-expansion-panel style="text-align: left;" id="pnlPerm" *ngIf="config.user.level==1">
      <mat-expansion-panel-header>Permissions associées à votre profil</mat-expansion-panel-header>
      <div style="width: 96%;margin:2%;">
        <app-tuto label="Les permissions permettent de voir tout ce que votre profil vous permet de faire sur {{environment.appname}}"></app-tuto>
        <br>
        Actuellement vous avez accès
        <div style="text-align: left;line-height: 120%;" class="mat-body-1" [innerHTML]="show_perm"></div>
        <br>
        <br>

        <button mat-raised-button (click)="open_perms()" title="Si votre profil n'est pas adapté à votre usage de Data Culture">
          Changer de profil
        </button>

        <app-tuto label="Certaines données de Data Culture sont en accès restreint. Pour y avoir accès il peut être nécéssaire d'en faire la demande à la FEMIS"></app-tuto>

      </div>
    </mat-expansion-panel>

    <mat-expansion-panel  style="text-align: center;" id="pnlJob">
      <mat-expansion-panel-header>Gérer votre compte</mat-expansion-panel-header>
      <br>
      <a class="mat-body-2 primary-color"
         [routerLink]="['/rgpd']">En savoir plus sur la conformité RGPD</a>
      <br>
      <br>

      <button class="app-button"
              mat-raised-button
              title="Accèder directement a votre profil d'ancien élève"
              (click)="edit_profil()">
       Editer votre profil
      </button>

      <button class="app-button"
              mat-raised-button
              title="Votre profil d'ancien.ne élève n'apparait plus dans l'annuaire"
              (click)="clear_profil(true)">
        <div class="bloc-bouton">Retirer le profil<br>de l'annuaire</div>
      </button>

      <button class="app-button"
              mat-raised-button
              title="Votre profil d'ancien.ne élève n'apparait plus dans l'annuaire"
              (click)="clear_profil(false)">
        <div class="bloc-bouton">Remttre votre profil<br>de l'annuaire</div>
      </button>


      <button class="app-button"  color="accent"
              mat-raised-button
              title="Consiste à supprimer votre compte utilisateur de l'application"
              (click)="raz()">
        <div class="bloc-bouton">
          Effacer votre compte<br>
          utilisateur
        </div>
      </button>

    </mat-expansion-panel>



    <br><br>
    <button mat-raised-button (click)="open_profils()">
      Accèder à l'annuaire
    </button>

    <button mat-raised-button (click)="open_movies()">
      Chercher un film
    </button>







  </mat-accordion>


</div>
