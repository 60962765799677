<div class="mainform" style="text-align: center !important;width: 100%;">
  <h3>{{profil?.firstname}} {{profil?.lastname?.toUpperCase()}}</h3>

  <app-hourglass [message]="message"></app-hourglass>
  <br>

  <div *ngIf="showAddWork==0 && profil" style="display: inline-block;max-width: 950px;width: 95%">
    <mat-accordion style="width:100%;">
      <!--      Civilité -->
      <mat-expansion-panel  id="pnlAdmin">
        <mat-expansion-panel-header>
          Civilité
        </mat-expansion-panel-header>

        <div style="height:175px;padding:5px;display: inline-block;position:relative;border: 1px solid grey;">
          <app-upload-file (uploaded)="change_photo($event)"
                           [zone]="true" icon="edit"
                           *ngIf="profil.photo==''"
                           width="150px" height="175px" [maxsize]="100000000"
                           title="Associer aux visuels un fichier de descriptions / attributs"
                           extensions=".jpg,.png,.jpeg">
          </app-upload-file>

          <mat-icon style="position:absolute;left:5px;top:10px;display:block;cursor:pointer;"
                    *ngIf="profil.photo.length>0"
                    (click)="profil.photo=''">cancel</mat-icon>
          <img [src]="profil.photo" style="height: 100%;" *ngIf="profil.photo.length>0">
        </div>
        <br><br>

        <mat-checkbox
                (change)="save_profil($event,'public_photo')"
                style="margin-top: 15px;"
                *ngIf="profil.photo!=''"
                title="Cliquez sur cette photo pour la mettre a jour"
                [(ngModel)]="profil.public_photo">
          Photo visible sur ma page publique
        </mat-checkbox><br><br>

        <app-input label="Prénom" value_type="text" [value]="profil.firstname" (valueChange)="profil.firstname=$event"></app-input>
        <app-input label="Nom" value_type="text" [value]="profil.lastname" (valueChange)="profil.lastname=$event" help="Nom d'usage (ou nom d'artiste)"></app-input>

        <br>
        <app-input label="Nom de naissance" value_type="text" [value]="profil.aka" (valueChange)="profil.aka=$event" help="Nom utilisé à la FEMIS"></app-input>
        <br>

        <app-input label="Téléphone" value_type="text" [value]="profil.mobile" (valueChange)="profil.mobile=$event"></app-input>
        <app-input label="Email" value_type="text" [value]="profil.email" (valueChange)="profil.email=$event" (validate)="update_email($event)"></app-input>

        <br>







<!--        <mat-form-field appearance="fill" class="app-field">-->
<!--          <mat-label>Adresse</mat-label>-->
<!--          <input matInput type="text"  [(ngModel)]="profil.address">-->
<!--        </mat-form-field>-->
<!--        <br>-->

<!--        <mat-form-field appearance="fill" class="app-field" style="width:20%;margin-right: 2%;">-->
<!--          <mat-label>Code postal</mat-label>-->
<!--          <input matInput type="text" max="5" [(ngModel)]="profil.cp">-->
<!--        </mat-form-field>-->
<!--        &nbsp;-->
<!--        <mat-form-field appearance="fill" class="app-field" style="width: 65%">-->
<!--          <mat-label>Ville</mat-label>-->
<!--          <input matInput type="text" [(ngModel)]="profil.town">-->
<!--        </mat-form-field>-->


        <mat-action-row>
          <button color="primary" class="icon-button" mat-icon-button (click)="quit()">
            <mat-icon>save</mat-icon>
          </button>
        </mat-action-row>

      </mat-expansion-panel>

      <!--      Réseau sociaux -->
      <mat-expansion-panel  style="text-align: left;" id="pnlSocial">
        <mat-expansion-panel-header>
          Réseaux sociaux / Annuaires
        </mat-expansion-panel-header>

        Vos adresses sur les réseaux sociaux<br>
        <table style="width: 100%;vertical-align: text-top;">
          <tr *ngFor="let social of socials">
            <td style="width:90%">
              <app-input [label]="social.name" value_type="text"
                         style="margin:5px;"
                         [value]="profil[social.name]"
                         help="Cette adresse peut être partager ou pas sur votre page public"
                         [placeholder]="social.input" [maxlength]="50"
                         (valueChange)="profil[social.name]=$event;mustSave=true"
                         (focusout)="check_format(social)"></app-input>
            </td>
            <td style="font-size: x-large !important;">
<!--              TODO a terminer pour permettre le partage des liens-->
              <mat-icon *ngIf="false"
                        title="Cette expérience est visible de tous (public)"
                        style="cursor: pointer;display: inline;width:20px;margin:5px;margin-top:-10px;">
                visibility
              </mat-icon>

              <mat-icon *ngIf="true"
                        style="cursor: pointer;width: 20px;opacity:0.7;margin:5px;margin-top:-10px;"
                        title="Cette expérience est privée (seule vous pouvez la connaitre)"
                        >
                visibility_off
              </mat-icon>
            </td>
            <td style="font-size: small;vertical-align: text-top;text-align: center;line-height: 90%;">
              <br>
              {{social.message}}
            </td>
          </tr>
        </table>


        <mat-action-row>
          <button color="primary" class="icon-button"
                  [disabled]="!mustSave"
                  mat-icon-button (click)="quit()">
            <mat-icon>save</mat-icon>
          </button>
        </mat-action-row>

      </mat-expansion-panel>


      <!--      Mentorat-->
      <mat-expansion-panel  style="text-align: left;" id="pnlTutorat" *ngIf="config.hasPerm('tutorat')">
        <mat-expansion-panel-header>

          Tutorat


        </mat-expansion-panel-header>

        <table *ngIf="profil.sponsorBy"><tr style="width: 100%;">
          <td style="width: 100%;">Mon Tuteur:<br>
            <strong><small>{{profil.sponsorBy.fullname}}</small></strong>
          </td>
          <td style="width: 30px;text-align: right;">
            <button class="icon-button" mat-icon-button
                    title="Voir le profil de son tuteur"
                    (click)="open_profil(profil.sponsorBy)">
              <mat-icon>visibility</mat-icon>
            </button>
          </td>
          <td style="width: 30px;text-align: right;">
            <button class="icon-button" mat-icon-button
                    title="Contacter votre tuteur"
                    (click)="contact_profil(profil.sponsorBy)">
              <mat-icon>email</mat-icon>
            </button>
          </td>
          <td style="width: 30px;text-align: right;">
            <button class="icon-button" mat-icon-button
                    title="Ne plus avoir comme tuteur"
                    (click)="remove_tuteur()">
              <mat-icon>cancel</mat-icon>
            </button>
          </td>
        </tr></table>

        <br>

        <mat-checkbox
                (change)="save_profil($event,'acceptSponsor');"
                [checked]="profil.acceptSponsor">
          Accepte d'être tuteur
        </mat-checkbox>
        &nbsp;
        <button class="app-button" mat-button
                *ngIf="students.length>0"
                mat-raised-button
                title="Ecrire à tous"
                (click)="write_all()">
          Ecrire à tous
        </button>

        <div *ngIf="students.length>0" style="margin-top: 10px;">
          <strong>Mes tutorés</strong>
          <table>
            <tr *ngFor="let st of students" style="width: 100%;">
              <td style="width: 100%;">
                {{st.lastname}}
              </td>
              <td style="width: 30px;text-align: right;">
                <button class="icon-button" mat-icon-button
                        title="Voir le profil"
                        (click)="open_profil(st)">
                  <mat-icon>visibility</mat-icon>
                </button>
              </td>
              <td style="width: 30px;text-align: right;">
                <button class="icon-button" mat-icon-button
                        title="Le/la contacter"
                        (click)="contact_profil(st.id)">
                  <mat-icon>email</mat-icon>
                </button>
              </td>
              <td style="width: 30px;text-align: right;">
                <button class="icon-button" mat-icon-button
                        title="Ne plus avoir comme tuteur"
                        (click)="remove_student(st.id)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </td>
            </tr></table>
        </div>
      </mat-expansion-panel>

      <!--      Job-->
      <mat-expansion-panel  style="text-align: left;" id="pnlJob" *ngIf="config.hasPerm('job_search')">
        <mat-expansion-panel-header>

          Offres d'emplois

        </mat-expansion-panel-header>

        <mat-form-field appearance="outline" class="app-field" style="width: 70%;max-width: 250px;font-size: small;">
          <mat-label>Critères de recherche</mat-label>
          <input matInput type="text" max="50" [(ngModel)]="query">
        </mat-form-field>

        <!--        <button class="icon-button" mat-icon-button-->
        <!--                mat-raised-button-->
        <!--                title="Ouvrir tous les sites simultanément"-->
        <!--                (click)="open_all_site('search')">-->
        <!--          <mat-icon>search</mat-icon>-->
        <!--        </button>-->
        <br>


        <table style="width:100%;">
          <tr style="width:100%;font-size: small;padding-bottom: 3px;" *ngFor="let site of jobsites">
            <td style="width: fit-content;">
              <strong>{{site.name}}</strong><br>
              <div style="line-height: 90%;font-size: small;">{{site.description}}</div>
            </td>
            <td style="width:110px;text-align: right;">
              <mat-icon (click)="opensite(site,'search')">search</mat-icon>
              <mat-icon title="Se connecter" (click)="opensite(site,'login')">person</mat-icon>
            </td>
          </tr>
        </table>
      </mat-expansion-panel>


      <!--      Présence sur Internet -->
      <mat-expansion-panel [expanded]="expanded_internet_pnl" id="pnlInternet">
        <mat-expansion-panel-header>

          Présence internet

        </mat-expansion-panel-header>
        <div class="mat-body-1 label">Voir et optimiser sa présence sur Internet</div>
        <table style="width:100%;">
          <tr *ngFor="let ref of profil.links" style="text-align: left;">
            <td>{{ref.text}}</td>
            <td style="width:50px;text-align: right;">
              <mat-icon style="font-size: medium;cursor: pointer;" (click)="open_page(ref.url,'ref')">search</mat-icon>
              <mat-icon style="font-size: medium;cursor: pointer;" (click)="open_faqs(ref.text)" [title]="'Optimiser sa présence sur '+ref.text">help</mat-icon>
            </td>
          </tr>
        </table>
      </mat-expansion-panel>

      <!--      Expérience -->
      <mat-expansion-panel  [expanded]="expanded_experience_pnl" id="pnlExperience">
        <mat-expansion-panel-header>Expériences</mat-expansion-panel-header>

        <table style="width: 100%;font-size: small;">
          <div *ngIf="works.length==0 && message.length==0">
            Aucune expérience identifiée.
          </div>

          <tr *ngFor="let work of works" style="width:100%;text-align: left;">
            <td>-</td>
            <td style="line-height: 100%;width: fit-content;">
              <span *ngIf="work.year">{{work.year}}</span>
              <span *ngIf="!work.year">En cours</span>
              :
              {{work.job}} sur
              <a [title]="work.source" (click)="open_movie(work.title)" class="primary-color">{{work.title}}</a>
              <br>
              <div *ngIf="work.dtEnd">
                en {{work.dtEnd | date:'yyyy'}}
                <br>
              </div>

            </td>
            <td style="text-align: right;width:70px;" name="experience_panel">

              <mat-icon
                      name="cmdEdit"
                      title="Editer cette expérience"
                      *ngIf="config.hasPerm('edit_work')"
                      (click)="edit_work(work)">
                edit
              </mat-icon>

              <mat-icon *ngIf="work.public"
                        name="cmdVisibilityOn"
                        title="Cette expérience est visible de tous (public)"
                        style="cursor: pointer;font-size: medium;display: inline;width:20px;" (click)="_private(work)">
                visibility
              </mat-icon>

              <mat-icon *ngIf="!work.public"
                        style="cursor: pointer;font-size: medium;width: 20px;opacity:0.7;"
                        name="cmdVisibilityOff"
                        title="Cette expérience est privée (seule vous pouvez la connaitre)"
                        (click)="_private(work)">
                visibility_off
              </mat-icon>

              <mat-icon style="cursor: pointer;font-size: medium;width: 20px;"
                        [style.color]="work.notification_color"
                        name="cmdError"
                        [title]="work.error_notification=='' ? 'Signaler une erreur' : 'Votre message: \''+work.error_notification+'\' est en cours de traitement'"
                        (click)="error_notify(work)">
                report
              </mat-icon>

              <mat-icon style="cursor: pointer;font-size: medium;display: inline;"
                        name="cmdDeleteExperience"
                        *ngIf="config.hasPerm('edit_work')"
                        title="Supprimer cette expérience"
                        (click)="del_work(work)">
                cancel
              </mat-icon>

            </td>

          </tr>
        </table>

        <app-faq-link *ngIf="works.length>0" faq="bug_works" style="margin-top: 10px;display:inline-block;color:gray;">
          En savoir plus sur d'éventuelles expériences manquantes
        </app-faq-link>

        <mat-action-row  *ngIf="config.hasPerm('edit_work')">
          <button class="app-button" mat-raised-button
                  color="primary"
                  id="cmdAddExperience"
                  (click)="add_pow()">
            <div class="bloc-bouton">Ajouter une<br>expérience</div>
          </button>

        </mat-action-row>
      </mat-expansion-panel>



      <!-- Récompenses-->
      <mat-expansion-panel  [expanded]="false" id="pnlRecompenses">
        <mat-expansion-panel-header>
          Nominations / Récompenses
        </mat-expansion-panel-header>
        <br>
        <app-tuto label="Dans le tableau ci-dessous, les nominations sont grisées et les récompenses gagnées en clair"></app-tuto>
        <table style="width: 100%;font-size: small;">
          <tr *ngFor="let award of awards" style="width:100%;text-align: left;vertical-align: text-top;" [title]="'pour '+award.pow.title" [style.opacity]="award?.winner ? 1.0 : 0.5">
            <td>-</td>
            <td style="line-height: 100%;width: fit-content;">{{award?.year}}:</td>
            <td style="line-height: 100%;width: fit-content;">
              {{award?.description}}<br>
              <small style="color:lightgrey">{{award?.festival?.title}}</small>
            </td>
            <td style="text-align: right;width:70px;cursor: pointer;" name="experience_panel">
              <mat-icon style="display: inline;font-size: medium;" *ngIf="config.hasPerm('admin')"
                        name="cmdDeleteAward"
                        (click)="del_award(award)">
                delete
              </mat-icon>
              <mat-icon style="display: inline;font-size: medium;"
                        title="Voir la source"
                        name="cmdOpenSourceAward"
                        (click)="open_source_award(award)">
                link
              </mat-icon>
              <mat-icon style="cursor: pointer;font-size: medium;width: 20px;"
                        name="cmdErrorAward"
                        title="Signaler une erreur"
                        (click)="error_notify(null,award)">
                report
              </mat-icon>
              <mat-icon [title]="award.pow.title"
                        (click)="open_movie(award.pow.title)"
                        style="display: inline;font-size: medium;">
                local_movies
              </mat-icon>
            </td>
          </tr>
        </table>
        <mat-action-row>
          <button class="app-button" mat-raised-button
                  color="primary"
                  *ngIf="works.length>0"
                  id="cmdAddAward"
                  (click)="add_award()">
            <div class="bloc-bouton">Ajouter une<br>récompense</div>
          </button>

        </mat-action-row>

      </mat-expansion-panel>


      <!--      Relations -->
      <mat-expansion-panel  [expanded]="false" id="pnlRelations" *ngIf="relations?.length>0">
        <mat-expansion-panel-header>

          Réseau pro

        </mat-expansion-panel-header>
        Vous avez travaillé avec:<br>
        <div *ngFor="let relation of relations" style="cursor: pointer" (click)="open_profil(relation)">
          {{relation.firstname+" "+relation.lastname}}
        </div>
      </mat-expansion-panel>

    </mat-accordion>

    <br>
    <div *ngIf="works.length>0 && message==''" style="text-align: center;width: 100%;">
      <br>
      <app-input value_type="boolean"  *ngIf="config.user.profil_name=='admin'" style="opacity: 0.5;"
                 title="Le profil n'apparait plus dans l'annuaire mais reste présent dans les statistiques"
                 [value]="profil.online" (valueChange)="update_online_profil($event)" label="Apparaitre dans l'annuaire"></app-input>
      <br>
      <button class="app-button" mat-raised-button
              id="cmdPreview"
              title="Permet de voir la page publique qui liste vos expériences"
              *ngIf="works?.length>0 && profil.online"
              (click)="open_page(profil.public_url,'page_public')">
        <div class="bloc-bouton">Voir la page<br>publique</div>
      </button>
      <br>
    </div>

    <button mat-icon-button
            class="icon-button"
            id="cmdQuality"
            title="Traitement qualité appliqué à la fiche"
            *ngIf="works?.length>0 && config.hasPerm('quality')"
            (click)="apply_quality()">
      <mat-icon style="font-size: x-large;">shower</mat-icon>
    </button>


    <button mat-icon-button
            class="icon-button"
            id="cmdDeletePows"
            title="Efface les films"
            *ngIf="works?.length>0 && config.hasPerm('delete_pow')"
            (click)="delete_pows()">
      <mat-icon style="font-size: x-large;">delete</mat-icon>
    </button>

    <button mat-icon-button
            class="icon-button"
            id="cmdClear"
            title="Sont effacées uniquement les expériences issue des annuaires publiques"
            *ngIf="works?.length>0"
            (click)="reset_contrib_profil()">
      <mat-icon style="font-size: x-large;">loop</mat-icon>
    </button>

    <button mat-icon-button class="icon-button"
            title="Déclenche une recherche profonde de vos expériences (plus long)"
            id="cmdDeepAnalyse"
            (click)="analyse(true)">
      <mat-icon style="font-size: x-large;">add_circle</mat-icon>
    </button>

    <button mat-icon-button class="icon-button"
            title="Déclenche une recherche de vos expériences sur les principaux portail cinématographique"
            id="cmdAnalyse"
            (click)="analyse(false)">
      <mat-icon style="font-size: x-large;">search</mat-icon>
    </button>

    <button mat-icon-button class="icon-button"
            title="Audit qualité sur le profil"
            id="cmdQualityAudit"
            (click)="quality()">
      <mat-icon style="font-size: x-large;">check_box</mat-icon>
    </button>


    <br><br>
    <p style="font-size: small;opacity:0.5;">
      Dernière mise a jour le {{profil.dtLastUpdate | date:"dd/MM/yy"}}
    </p>
  </div>


  <!--  -->
  <!--  <div *ngIf="showAddWork==1" style="text-align: left;">-->
  <!--    <hr>-->
  <!--    <h3>Sélectionner le film à ajouter</h3>-->
  <!--    <div  style="width: 92%;margin-left: 4%;">-->
  <!--      <mat-form-field>-->
  <!--        <mat-label>Filter</mat-label>-->
  <!--        <input matInput (keyup)="applyFilter($event)" -->
  <!--               placeholder="titre" #input>-->
  <!--      </mat-form-field>-->

  <!--      <table mat-table-->
  <!--             [dataSource]="dataSource"-->
  <!--             class="mat-elevation-z2"-->
  <!--             style="width: 100%;text-align: left;">-->

  <!--        &lt;!&ndash; Position Column &ndash;&gt;-->
  <!--        <ng-container matColumnDef="title">-->
  <!--          <th mat-header-cell *matHeaderCellDef>Titre</th>-->
  <!--          <td mat-cell *matCellDef="let element"> {{element?.title}} </td>-->
  <!--        </ng-container>-->

  <!--        <ng-container matColumnDef="dtStart">-->
  <!--          <th mat-header-cell *matHeaderCellDef>Réalisé le</th>-->
  <!--          <td mat-cell *matCellDef="let element"> {{element?.dtStart | date:"YYYY"}} </td>-->
  <!--        </ng-container>-->

  <!--        <ng-container matColumnDef="sel">-->
  <!--          <th mat-header-cell *matHeaderCellDef>Sélectionner</th>-->
  <!--          <td mat-cell *matCellDef="let element">-->
  <!--            <button class="icon-button"-->
  <!--                    mat-icon-button-->
  <!--                    (click)="select(element)">-->
  <!--              <mat-icon>checkmark</mat-icon>-->
  <!--            </button>-->
  <!--          </td>-->
  <!--        </ng-container>-->

  <!--        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
  <!--        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->

  <!--        &lt;!&ndash; Row shown when there is no matching data. &ndash;&gt;-->
  <!--        <tr class="mat-row" *matNoDataRow>-->
  <!--          <td class="mat-cell" colspan="4">Aucune donnée ne répond au filtre "{{input.value}}"</td>-->
  <!--        </tr>-->
  <!--      </table>-->

  <!--      <br>-->
  <!--      <button class="app-button" mat-raised-button-->
  <!--              (click)="add_pow()">-->
  <!--        Nouveau film-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->

  <div *ngIf="add_works.length>0">
    <h3>
      Précisez votre contribution sur "{{current_work.title}}"
      <span *ngIf="current_work.year"> ({{current_work.year}})</span>
    </h3>
    <br>
    <div *ngFor="let add_work of add_works" class="mat-elevation-z4">
      <div *ngIf="showAddWork==2 || showAddWork==3 && add_work.state!='D'" style="width:100%;text-align: center;margin-top: 15px;">

        <div style="display: flex;width:100%;justify-content: center;">
          <app-input [options]="config.jobs" label="Poste occupé" value_type="list"
                     [value]="add_work.job" (valueChange)="add_work.job=$event"
                     help="Sélectionner le poste que vous aviez sur le projet"></app-input>

          <app-input label="Heures travaillées" value_type="number" width="100px"
                     [value]="add_work.duration" (valueChange)="add_work.duration=$event;add_work.state='E';"
                     title="Nombre d'heures de travail réalisé sur le projet"></app-input>

          <app-input label="Revenu total" [value]="add_work.earning" (valueChange)="add_work.earning=$event;add_work.state='E';"
                     title="Revenu brut pour toute la durée (Donnée anonymisée uniquement utilisée sous forme de moyenne pour les statistiques)" width="100px"></app-input>

          <app-input label="Commentaires" [value]="add_work.comment" (valueChange)="add_work.comment=$event;add_work.state='E';"
                     maxlength="200"
                     title="Détailler l'expérience, difficultés rencontrées, bénéfices"></app-input>

          <button mat-raised-button *ngIf="add_work.state!='D'" (click)="add_work.state='D'">Supprimer</button>
          <mat-icon *ngIf="add_work.state=='E'">edit</mat-icon>

        </div>

        <br>

        <div style="display: flex;margin-top: 10px;width:90%;justify-content: space-around;">
          <app-scoring [value]="add_work.score_school" (valueChange)="add_work.score_school=$event;add_work.state='E'">
            Votre formation à la FEMIS vous a-t'elle aidé pour cette expérience ?
          </app-scoring><br>

          <app-scoring [value]="add_work.score_salary" (valueChange)="add_work.score_salary=$event;add_work.state='E'">
            Votre rémunération etait elle au niveau de vos attentes ?
          </app-scoring><br>

          <app-scoring [value]="add_work.score_skill" (valueChange)="add_work.score_skill=$event;add_work.state='E'">
            Votre rémunération etait elle au niveau de vos attentes ?
          </app-scoring>

        </div>
      </div>

    </div>



    <br>

    <button class="app-button" mat-raised-button
            (click)="cancel()">
      Anuler
    </button>

    <button class="app-button" color="primary" mat-raised-button
            title="Ajouter ce projet à votre expérience"
            (click)="save_newwork()">
      <div class="bloc-bouton">Enregistrer</div>
    </button>
  </div>

</div>
