
<div appFileDragNDrop (filesChangeEmiter)="import($event)"
     [style]="style_border"
     [title]="title"
     [style.width]="width" [style.background-image]="src">

  <input #csvInput
         type="file"
         style="display: none"
         onclick="this.value=null"
         [multiple]="true"
         (change)="import($event)"
         [accept]="extensions"/>

  <div *ngIf="icon.length>0" style="cursor: pointer;display:inline-block;">
    <button mat-icon-button (click)="csvInput.click()" *ngIf="!icon.startsWith('http')">
      <mat-icon>{{icon}}</mat-icon>
    </button>
    <img style="width:50px;height:50px;" *ngIf="icon.startsWith('http')" [src]="icon" alt="Image du NFT">
  </div>

  <button mat-raised-button
          style="display:block;position: absolute;width: max-content;left:50%;top:50%;transform: translate(-50%,-50%);margin-top:0;margin-bottom: 0;"
          *ngIf="icon.length==0"
          (click)="csvInput.click()">
    <ng-content style="padding: 5px;"></ng-content>
  </button>

  <button mat-raised-button
          *ngIf="show_cancel"
          class="app-button"
          (click)="cancel()">
    Annuler
  </button>
</div>