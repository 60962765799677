<div class="mainform">

  <app-tuto [if]="query.value.indexOf('*')>-1"
            label="Le symbole * permet de faire une recherche sur le début d'un nom, par exemple DUP* retourne tous les noms commençant par DUP">
  </app-tuto>


  <div *ngIf="show_first_screen && query.value==''" class="main-message"
       style="text-align: center;display:inline-block;min-width:300px;width:60%;margin-top:50px;">
    {{environment.search_intro}}

    <span *ngIf="config?.infos_server?.content">
      parmi les
      <span class="primary-color">{{config.infos_server.content.profils}}</span>
      profils disponibles
    </span>

    <mat-icon style="display: inline-block;margin-bottom: -5px;cursor: pointer;"
              *ngIf="config.user?.level>0"
              (click)="open_chart()">bar_chart</mat-icon>
  </div>

  <div [@moveUp]="toCenter ? 'middle' : 'up'"></div>

<!--  Barre de recherche standard -->
  <mat-form-field appearance="outline" class="search_zone mat-headline-6" >
    <input matInput matNativeControl id="txtSearch" style="margin: 0;" autofocus
           title="Un ou plusieurs mots pour effectuer des recherches dans les métiers, les noms, les réalisations ..."
           (keyup)="onQuery($event)"
           [placeholder]="placeholder"
           [(ngModel)]="query.value">
    <div matSuffix style="font-size: medium;display: inline-block;">
      <mat-icon *ngIf="query.value?.length==0" style="opacity:0.6;"
                title="Comment construire vos requêtes"
                id="cmdHelp"
                (click)="help()">help</mat-icon>

      <!--Toolbar de recherche avancée -->
      <mat-icon *ngIf="query.value.length==0" style="opacity:0.6;margin-right: 5px;"
                title="Afficher l'annuaire par formation"
                id="cmdAdvanced"
                (click)="switch_motor()">add_circle</mat-icon>

      <mat-icon *ngIf="query.value?.length>0"
                style="margin-right: 15px;opacity: 0.6;"
                matSuffix
                id="cmdCancel"
                (click)="clearQuery()">cancel</mat-icon>

    </div>
  </mat-form-field>

  <button mat-raised-button *ngIf="config.hasPerm('admin')" (click)="import_profil()">Importer des profils</button>


  <!--  <mat-icon *ngIf="advanced_search && config.user?.level>0" style="margin-left:-10px;margin-bottom: -7px;"-->
<!--            id="cmdExpertMode"-->
<!--            title="Recherche multi-critère"-->
<!--            (click)="switch_motor()">add_circle</mat-icon>-->



  <!--  Critère de la recherche avancée -->
<!--  <div *ngIf="advanced_search" style="display: flex;margin-left:10%;width:80%;justify-content: center;align-items: center;">-->
<!--    <div *ngFor="let i of advanced_search" style="margin: 5px;width:150px;">-->

<!--      <mat-form-field [ngStyle]="{width:i.width}" appearance="fill"  class="mat-body-2">-->
<!--        <mat-label>{{i.label}}</mat-label>-->
<!--        <input matInput matNativeControl-->
<!--               *ngIf="i.type=='text'"-->
<!--               style="width: 100%;"-->
<!--               [title]="i.title"-->
<!--               [id]="i.id"-->
<!--               (keyup)="onQuery($event)"-->
<!--               placeholder=""-->
<!--               [(ngModel)]="i.value">-->

<!--        <mat-select *ngIf="i.type=='list'" [(ngModel)]="i.value" (selectionChange)="refresh()">-->
<!--          <mat-option *ngFor="let opt of i.options" [value]="opt">{{opt}}</mat-option>-->
<!--        </mat-select>-->

<!--      </mat-form-field>-->

<!--    </div>-->
<!--      <mat-icon style="cursor: pointer;color:lightgray;margin-left: 20px;margin-bottom: -5px;"-->
<!--                id="cmdSimpleMode"-->
<!--                title="Recherche simplifiée"-->
<!--                (click)="switch_motor()">remove_circle</mat-icon>-->

<!--  </div>-->


  <!--  gestion du tri-->
  <div *ngIf="profils?.length>10" style="display: inline-block;text-align: right;margin-top: -10px;" >
    <mat-form-field appearance="outline"
                    style="max-width:175px;font-size: small;margin-left:15px;">
      <mat-label>Trier par</mat-label>
      <mat-select id="selOrder" [(ngModel)]="order" (selectionChange)="refresh()">
        <mat-option *ngFor="let f of fields" [value]="f.value">{{f.field}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>




  <div style="font-size: small" *ngIf="profils?.length>3" >
    <div *ngIf="profils.length>20" style="display: inline-block;margin:5px;"
         title="Recherche avancée: notamment des recherches sur une partie du nom, du prenom en utilisant des *">
      <span *ngIf="profils.length==this.limit">+ de </span>
      {{profils.length}}
      <span (click)="switch_filter_pro('')" title="Voir tous les cursus" style="cursor: pointer">profils</span>

      <mat-icon style="cursor: pointer;font-size: medium;margin-bottom: -12px;"
                *ngIf="profils.length==this.limit"
                title="Afficher plus de profils"
                (click)="inc_limit()">
        add_circle
      </mat-icon>
    </div>
  </div>




  <div id="legende" style="width:90%;text-align: center;margin: 5px;margin-left:5%;"
       *ngIf="!config.isMobile() && profils.length>0" >
    <table style="display: inline-block;width:fit-content;max-width: 1000px;">
      <tr>
        <td class="material-icon" (click)="switch_filter_pro('S')">
          <mat-icon *ngIf="cursus_filter=='' || cursus_filter=='S'" class="fi-color" >circle</mat-icon>
          <mat-icon *ngIf="cursus_filter=='P'" class="fi-color" >radio_button_unchecked</mat-icon>
        </td>
        <td>Formation intiale</td>
        <td class="material-icon" (click)="switch_filter_pro('P')">
          <mat-icon *ngIf="cursus_filter=='' || cursus_filter=='P'" class="fc-color" >circle</mat-icon>
          <mat-icon *ngIf="cursus_filter=='S'" class="fc-color" >radio_button_unchecked</mat-icon>
        </td>
        <td>Formation professionnelle</td>

        <td *ngIf="config.show_student" class="material-icon"><mat-icon class="fa-color">circle</mat-icon></td>
        <td *ngIf="config.show_student" >Etudiants</td>
      </tr>
    </table>
  </div>




  <div class="div-center" style="margin-top: 20px;">
    <app-hourglass [message]="message"></app-hourglass>
  </div>

<!--  <div style="margin-top: 20px"-->
<!--       *ngIf="profils?.length==0 && message.length==0 && (query.value?.length>0 || this.advanced_search?.length>0)">-->
<!--    -->
<!--  </div>-->

  <app-tuto [if]="profils?.length>0"
            label="Cliquez sur les fiches pour voir les informations au dos">
  </app-tuto>


  <!-- Affichage des profils-->
  <div class="wrap-list" >
    <!--  <cdk-virtual-scroll-viewport [itemSize]="50">-->
    <app-profil *ngFor="let profil of profils"
            name="profils" class="app-card"
            (delete)="deleteProfil(profil)"
            [writeAccess]="config.user && config.user.profil==profil?.id || config.hasPerm('edit_profil')"
            (askfriend)="askfriend(profil)"
            (onclick)="openQuery($event)"
            style="margin: 12px;max-width: 500px;"
            [profil]="profil">
    </app-profil>

  </div>

  <!--  </cdk-virtual-scroll-viewport>-->
</div>



