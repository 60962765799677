<div class="input-elements" [style.width]="width" [style.max-width]="maxwidth" [ngClass]="fontname">

  <div style="padding:0;margin:2px;" *ngIf="value_type=='boolean'">
    <mat-checkbox [(ngModel)]="value"
                      style="width: 100%;padding:0;margin:0;"
                      color="primary"
                      (ngModelChange)="on_validate()"
                      [title]="infobulle || help">
      {{label}}
    </mat-checkbox>
  </div>

  <!-- input standard -->
  <mat-form-field appearance="fill"  [title]="help_input" style="width: 100%;padding: 0;"
                  [ngClass]="fontname"
                  *ngIf="(value_type=='text' || value_type=='number') && options.length==0">
    <mat-label *ngIf="label && label.length>0" class="mat-caption">{{label}}</mat-label>
    <input matInput
           [max]="max"
           [ngClass]="fontname"
           [min]="min"
           [type]="value_type"
           [maxlength]="maxlength"
           [(ngModel)]="value"
           [placeholder]="placeholder"
           [title]="infobulle"
           (focusin)="showHelp=true"
           (focusout)="showHelp=false"
           (mouseup)="on_key($event)"
           (keyup)="on_key($event)">


    <div matSuffix style="margin-right: 5px;opacity: 0.4;">
      <mat-icon *ngIf="icon_action && icon_action.length>0"
                style="display: inline-block;font-size: 20px;cursor: pointer;"
                (click)="call_action(value)">{{icon_action}}</mat-icon>


      <mat-icon *ngIf="value && value.length>8 && ((value_type=='text' && ((value.toString().startsWith('http://')) || value.toString().startsWith('https://'))) || force_preview)"
                title="Vérifier votre lien en l'ouvrant"
                style="display: inline-block;font-size: 20px;cursor: pointer;"
                (click)="explore(value)">visibility</mat-icon>

      <mat-icon *ngIf="showClear && value_type!='number' && value && value!.length>0"
                title="Effacer"
                style="display: inline-block;font-size: 20px;cursor: pointer;"
                (click)="on_clear()">cancel</mat-icon>

      <div *ngIf="value_type=='number' && unity && unity.length>0"
           style="display:inline-block;margin-top:8px;margin-right: 7px;opacity: 0.7;">{{unity}}</div>
    </div>

    <mat-hint *ngIf="showHelp" class="help-input">{{help}}</mat-hint>
  </mat-form-field>


<!--  Timepicker -->
  <mat-form-field *ngIf="value_type=='time'" >
    <mat-label>{{label}}</mat-label>
    <input matInput type="time" placeholder="Heure" [(ngModel)]="value"
           style="width: 150px;"
           (mouseup)="on_key($event)" (keyup)="on_key($event)">
  </mat-form-field>




<!--  Date picker voir : https://material.angular.io/components/datepicker/overview -->
  <mat-form-field appearance="fill" *ngIf="value_type=='date'" [formControl]="date_control">
    <mat-label>{{label}}</mat-label>
    <input matInput [matDatepicker]="value" >
    <mat-datepicker-toggle matIconSuffix [for]="picker_date"></mat-datepicker-toggle>
    <mat-datepicker #picker_date></mat-datepicker>
  </mat-form-field>



<!--  Date range-->
    <mat-form-field appearance="fill" *ngIf="value_type=='daterange'">
      <mat-label>{{label}}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker" >
        <input matStartDate  formControlName="start" placeholder="Date de début" (dateChange)="on_key($event)">
        <input matEndDate  formControlName="end" placeholder="Date de fin" (dateChange)="on_key($event)">
      </mat-date-range-input>
<!--      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
      <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
      <mat-date-range-picker #picker ></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Date de début non valide</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Date de fin non valide</mat-error>
    </mat-form-field>


<!--  Proposition d'options-->
  <div *ngIf="value_type=='options'">
    <div *ngFor="let opt of options">
      <mat-checkbox [(ngModel)]="opt.value" (ngModelChange)="on_key(null)">{{opt.label}}</mat-checkbox><br>
    </div>
  </div>

  <!-- input de type textarea-->
  <mat-form-field style="width: 100%;" *ngIf="cols>0 && rows>0" appearance="fill">
    <mat-label *ngIf="label.length>0" class="mat-caption">{{label}}</mat-label>

    <textarea [placeholder]="placeholder" title="infobull" matInput
              [ngClass]="fontname"
              [(ngModel)]="value"
              [rows]="rows" [cols]="cols" (focusin)="showHelp=true"
              (focusout)="showHelp=false" (keyup)="on_key($event)">
    </textarea>

    <mat-icon matSuffix *ngIf="showClear && value && value!.length>0" (click)="on_clear()" style="opacity: 0.7;">cancel</mat-icon>
    <mat-hint *ngIf="showHelp" class="mat-body-1 help-input">
      {{help}}
    </mat-hint>
  </mat-form-field>

  <button mat-raised-button
          *ngIf="label_button.length>0"
          style="height: 40px;margin-left:10px;"
          [disabled]="!value || value.length==0"
          [title]="help_button"
          (click)="on_validate()">
    {{label_button}}
  </button>

  <button mat-raised-button
          *ngIf="cancel_button.length>0"
          [title]="help_button"
          (click)="on_cancel()">
    {{cancel_button}}
  </button>

  <!--options list-->
  <mat-form-field  style="width:100%;overflow: hidden;" appearance="fill"
                   *ngIf="value_type=='list' && options.length>0">
    <mat-label *ngIf="label.length>0" class="mat-caption">{{label}}</mat-label>
    <mat-select [compareWith]="compareFn" [formControl]="valueCtrl" (valueChange)="sel_change($event)" [multiple]="multiselect" style="overflow: hidden;">
      <mat-option *ngFor="let option of options" [value]="option">
        <span [ngClass]="fontname">{{option.label}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>


  <!--options list images / images-->
  <div  style="width:100%;text-align: left;" *ngIf="(value_type=='images' || value_type=='listimages') && options.length>0" class="mat-elevation-z2">
    <div *ngIf="label.length>0" style="margin:10px;" class="mat-caption">{{label}}</div>
    <div style="overflow: scroll;" [style.height]="height">

      <mat-selection-list (selectionChange)="sel_change($event)" [multiple]="true" *ngIf="multiselect">
        <mat-list-option *ngFor="let option of options" [value]="option">
          <p matListItemLine [ngClass]="fontname">{{option.label}}</p>
        </mat-list-option>
      </mat-selection-list>

      <mat-selection-list  *ngIf="!multiselect">
        <mat-list-item *ngFor="let option of options" (click)="sel_change(option)">
          <img matListItemAvatar [src]="option.image" [style.width]="size_image" [style.height]="size_image" alt="Image de NFT">
          <p matListItemTitle [ngClass]="fontname">{{option.label}}</p>
        </mat-list-item>
      </mat-selection-list>


    </div>
  </div>



  <!--  Slider -->
  <div *ngIf="value_type=='slider' || value_type=='slide'" style="width: 100%;">
    <table *ngIf="label.length>0" style="width: 100%;" ><tr>
      <td style="text-align: left;width:80%;"><span class="mat-caption">{{label}}</span></td>
      <td style="text-align: right;overflow: hidden;margin-left:-10px;" class="mat-headline-6" [style.color]="color_value">
        <input [(ngModel)]="value" type="number" [max]="max" [min]="min"
               matInput inputmode="numeric"
               class="mat-caption color-primary"
               (ngModelChange)="direct_change_slider()"
               (focusout)="direct_change_slider()"
               style="display:inline-block;width:fit-content;border: none;background:none;font-size: medium;text-align: right;" [style.color]="color_value">
      </td>
      <td style="text-align: left;" *ngIf="unity.length>0" class="mat-caption color-primary">{{unity}}</td>
    </tr></table>
    <div style="margin-left:0;width:100%;margin-top: -20px;">
      <mat-slider [max]="max" [min]="min" [step]="step" [style.color]="color_value"
                  *ngIf="max>min" style="width:100%;"
                  (focusin)="showHelp=true" (focusout)="showHelp=false">
        <input matSliderThumb [(ngModel)]="value" (ngModelChange)="sel_change({value:$event})">
      </mat-slider><br>
      <div class="mat-body-1 help-input" style="margin-top:-15px;text-align: left;margin-left:5px;" [style.opacity]="showHelp ? 0.7 : 0">{{help}}</div>
    </div>

  </div>

  <ng-content></ng-content>
</div>